<template>
  <div class="header-bar">
    <Menubar class="header-bar_menu">
      <template #start>
        <a target="_self" href="/">
          <img
            class="logo"
            src="/images/mck-compile-logo.svg"
            alt="McKesson Compile logo"
          />
        </a>
      </template>

      <template #end>
        <div
          v-if="appStore.hasUserInfo()"
          class="d-flex align-items-center gap-1"
        >
          <CPNotification />
          <button
            v-tooltip.bottom="'Contact Us'"
            class="btn-icon"
            aria-label="Contact Us"
            @click="onContactUs()"
          >
            <i class="bi bi-envelope" />
          </button>
          <Select
            dropdown-icon="bi bi-caret-down-fill"
            :options="ddUserItems"
            option-label="name"
            :placeholder="appStore.user.name"
            class="ms-2"
            @change="onChangeHandler($event)"
          />
        </div>
        <div v-else>
          <a
            v-if="embedPublicUser()"
            target="_blank"
            href="https://www.compile.com/contact"
            class="mr-4"
          >
            <button variant="outline" class="btn btn-outline">
              Contact us
            </button>
          </a>
        </div>
      </template>
    </Menubar>
  </div>
</template>

<script lang="ts" setup>
  import type { IDropdownChangeEvent, IUserDropDown } from '~/stores/types/app'

  // Runtime config composable to refer environment variables
  const config = useRuntimeConfig()

  // Store
  const appStore = useAppStore()

  // Properties
  const ddUserItems: IUserDropDown[] = [
    {
      name: 'Change Password',
      id: 'changePassword',
      path: '/accounts/password/change/',
    },
    { name: 'Sign Out', id: 'signOut', path: '/accounts/logout/' },
  ]

  // Hooks
  onMounted(() => {
    if (appStore.user.is_user_admin || appStore.user.is_superuser) {
      ddUserItems.unshift({
        name: 'User Admin',
        id: 'userAdmin',
        path: '/user-admin/',
      })
    }
  })

  // Methods and Event handlers
  const onChangeHandler = async (selectedItem: IDropdownChangeEvent) => {
    let path = ''
    let target = '_self'
    if (selectedItem.value.id === 'userAdmin') {
      path = HelperMethods.urlWithRedirect(
        config.public.adminURL + selectedItem.value.path,
      )
      target = '_blank'
    } else {
      path = HelperMethods.urlWithRedirect(
        config.public.accountsURL + selectedItem.value.path,
      )
    }

    await navigateTo(path, {
      external: true,
      open: {
        target,
      },
    })
  }

  const embedPublicUser = () => {
    return appStore.embedPublicUser
  }

  const onContactUs = () => {
    const contactUsURL = HelperMethods.urlWithRedirect(
      config.public.accountsContactUsURL as string,
    )
    window.open(contactUsURL, '_self')
  }
</script>
<style lang="scss" scoped>
  @import '@/assets/scss/_color-palette.scss';

  .header-bar {
    &_menu {
      background-color: $dark-blue;
      height: 3.875rem; //62px
      border: none;
      border-bottom: 1px solid $border-gray;
      border-radius: 0;

      .logo {
        height: 1.75rem;
      }
    }
  }

  .btn-outline {
    border: 1px solid $compile-text-color;
    border-radius: 0.5rem !important;
    @include font-style(14px, 500, $white);
  }
</style>
